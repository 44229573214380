import Typography from '@mui/material/Typography';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import Link from '@mui/material/Link'

import circle from '../../sources/work_process/circle.svg'
import line from '../../sources/work_process/line_connect.png'
import process1 from '../../sources/work_process/process1_research.svg'
import process2 from '../../sources/work_process/process2_plan.svg'
import process3 from '../../sources/work_process/process3_design.svg'
import process4 from '../../sources/work_process/process4_development.svg'
import process5 from '../../sources/work_process/process5_testing.svg'
import process6 from '../../sources/work_process/process6_delivery.svg'

export default function WorkProcess() {
    const { t } = useTranslation();

    return (
        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
            <Typography paddingBottom={10} sx={{color:'#ffffff', textAlign:'center', fontFamily:'Inria Sans', fontSize:'32px', fontWeight:700}}>{t('WorkProcess.title')}</Typography>
            <Grid container direction="row" 
            sx={{
                justifyContent: "center",
                alignItems: "center",
                
            }}>
                {/* Process 1 */}
                <Grid item xs={2} container direction="column" 
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Box sx={{backgroundImage:`url(${circle})`, width:'102px', height:'102px',}} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                    <img
                        src={process1}
                        alt="process1"
                        width="65%"
                        height="65%"
                    />
                    </Box>
                    <Typography paddingTop={4} sx={{color:'#ffffff', textAlign:'center', fontFamily:'Inria Sans', fontSize:'20px', fontWeight:400, textTransform:'uppercase',}}>
                        {t('WorkProcess.process1')}
                    </Typography>
                </Grid>

                {/* Process 2 */}
                <Grid item xs={2} container direction="column" 
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Box sx={{backgroundImage:`url(${circle})`, width:'102px', height:'102px',}} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                    <img
                        src={process2}
                        alt="process2"
                        width="65%"
                        height="65%"
                    />
                    </Box>
                    <Typography paddingTop={4} sx={{color:'#ffffff', textAlign:'center', fontFamily:'Inria Sans', fontSize:'20px', fontWeight:400, textTransform:'uppercase',}}>
                        {t('WorkProcess.process2')}
                    </Typography>
                </Grid>

                {/* Process 3 */}
                <Grid item xs={2} container direction="column" 
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Box sx={{backgroundImage:`url(${circle})`, width:'102px', height:'102px',}} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                    <img
                        src={process3}
                        alt="process3"
                        width="65%"
                        height="65%"
                    />
                    </Box>
                    <Typography paddingTop={4} sx={{color:'#ffffff', textAlign:'center', fontFamily:'Inria Sans', fontSize:'20px', fontWeight:400, textTransform:'uppercase',}}>
                        {t('WorkProcess.process3')}
                    </Typography>
                </Grid>

                {/* Process 4 */}
                <Grid item xs={2} container direction="column" 
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Box sx={{backgroundImage:`url(${circle})`, width:'102px', height:'102px',}} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                    <img
                        src={process4}
                        alt="process4"
                        width="65%"
                        height="65%"
                    />
                    </Box>
                    <Typography paddingTop={4} sx={{color:'#ffffff', textAlign:'center', fontFamily:'Inria Sans', fontSize:'20px', fontWeight:400, textTransform:'uppercase',}}>
                       {t('WorkProcess.process4')}
                    </Typography>
                </Grid>

                {/* Process 5 */}
                <Grid item xs={2} container direction="column" 
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Box sx={{backgroundImage:`url(${circle})`, width:'102px', height:'102px',}} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                    <img
                        src={process5}
                        alt="process5"
                        width="65%"
                        height="65%"
                    />
                    </Box>
                    <Typography paddingTop={4} sx={{color:'#ffffff', textAlign:'center', fontFamily:'Inria Sans', fontSize:'20px', fontWeight:400, textTransform:'uppercase',}}>
                      {t('WorkProcess.process5')}
                    </Typography>
                </Grid>

                {/* Process 6 */}
                <Grid item xs={2} container direction="column" 
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Box sx={{backgroundImage:`url(${circle})`, width:'102px', height:'102px',}} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                    <img
                        src={process6}
                        alt="process6"
                        width="65%"
                        height="65%"
                    />
                    </Box>
                    <Typography paddingTop={4} sx={{color:'#ffffff', textAlign:'center', fontFamily:'Inria Sans', fontSize:'20px', fontWeight:400, textTransform:'uppercase',}}>
                       {t('WorkProcess.process6')}
                    </Typography>
                </Grid>


            </Grid>

        </Box>




    )

}