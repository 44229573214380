import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Link as MuiLink } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ChangeLanguage from './ChangeLanguage.js';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import KadimaLogo from '../../sources/kadima_logo.png'

function AppBar() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const appBarBackgroundColor = "rgba(255, 255, 255, 0.80)";

  const handleNavigation = (sectionId) => {
    if (location.pathname === '/') {
      // If on home page, scroll to section
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      // If on another page, navigate to home page with hash
      navigate(`/#${sectionId}`);
    }
  };

  return (
    <Box sx={{ height: 'auto', width: '100%', backgroundColor: appBarBackgroundColor }}>
      <Grid paddingLeft={5} paddingRight={5} paddingTop={4} paddingBottom={2} container direction="row" sx={{justifyContent: "flex", alignItems: "center" }}>
        <Grid item xs={3} container spacing={2} direction="row" sx={{ justifyContent: "flex-start", alignItems: "center" }}>
          <MuiLink onClick={() => handleNavigation('introduction')} sx={{ cursor: 'pointer' }}>
            <img src={KadimaLogo} alt="Kadima Logo" height="50px" width="auto" />
          </MuiLink>
        </Grid>

        <Grid item xs={9} container gap={2} spacing={2} direction="row" sx={{ justifyContent: "flex-end", alignItems: "center" }}>
          <MuiLink onClick={() => handleNavigation('ourServices')} sx={{ textDecoration: "none", color: "black", cursor: 'pointer' }}>
            <Typography sx={{ '&:hover': { textDecoration: 'underline' } }} color="#59A9D6">
              {t('TopBar.ourServices')}
            </Typography>
          </MuiLink>
          <MuiLink onClick={() => handleNavigation('ourProducts')} sx={{ textDecoration: "none", color: "black", cursor: 'pointer' }}>
            <Typography sx={{ '&:hover': { textDecoration: 'underline' } }} color="#59A9D6">
              {t('TopBar.ourProducts')}
            </Typography>
          </MuiLink>
          <MuiLink onClick={() => handleNavigation('contactUs')} sx={{ textDecoration: "none", color: "black", cursor: 'pointer' }}>
            <Typography sx={{ '&:hover': { textDecoration: 'underline' } }} color="#59A9D6">
              {t('TopBar.contactUs')}
            </Typography>
          </MuiLink>

          <Box>
            <ChangeLanguage />
          </Box>
          
          
        </Grid>
      </Grid>
    </Box>
  );
}

export default AppBar;