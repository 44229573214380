import Typography from '@mui/material/Typography';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import Link from '@mui/material/Link'

import circle from '../../sources/our_services/circle.svg'
import service1 from '../../sources/our_services/service1_logo_design.svg'
import service2 from '../../sources/our_services/service2_graphics.svg'
import service3 from '../../sources/our_services/service3_animations.svg'
import service4 from '../../sources/our_services/service4_UI_UX.svg'
import service5 from '../../sources/our_services/service5_photo_video_editing.svg'
import service6 from '../../sources/our_services/service6_AI.svg'
import service7 from '../../sources/our_services/service7_website.svg'
import service8 from '../../sources/our_services/service8_mobile_app.svg'
import service9 from '../../sources/our_services/service9_software_system.svg'
import service10 from '../../sources/our_services/service10_VR_AR.svg'
import service11 from '../../sources/our_services/service11_blockchain.svg'
import service12 from '../../sources/our_services/service12_metaverse.svg'

export default function OurServices() {
    const { t } = useTranslation();

    return (
        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
            <Typography paddingBottom={10} sx={{color:'#ffffff', textAlign:'center', fontFamily:'Inria Sans', fontSize:'32px', fontWeight:700}}>{t('OurServices.title')}</Typography>
            <Grid container direction="row" rowSpacing={12} columnSpacing={{ xs: 0 }}
            sx={{
                justifyContent: "center",
                alignItems: "center",
            }}>
                {/* Service 1 */}
                <Grid item xs={4} container direction="column" 
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Box sx={{backgroundImage:`url(${circle})`, width:'102px', height:'102px',}} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                    <img
                        src={service1}
                        alt="service1"
                        width="65%"
                        height="65%"
                    />
                    </Box>
                    <Typography paddingTop={6} sx={{color:'#ffffff', textAlign:'center', fontFamily:'Inria Sans', fontSize:'20px', fontWeight:400, textTransform:'uppercase',}}>
                        {t('OurServices.service1')}
                    </Typography>
                </Grid>

                {/* Service 2 */}
                <Grid item xs={4} container direction="column" 
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Box sx={{backgroundImage:`url(${circle})`, width:'102px', height:'102px',}} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                    <img
                        src={service2}
                        alt="service2"
                        width="65%"
                        height="65%"
                    />
                    </Box>
                    <Typography paddingTop={6} sx={{color:'#ffffff', textAlign:'center', fontFamily:'Inria Sans', fontSize:'20px', fontWeight:400, textTransform:'uppercase',}}>
                        {t('OurServices.service2')}
                    </Typography>
                </Grid>

                {/* Service 3 */}
                <Grid item xs={4} container direction="column" 
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Box sx={{backgroundImage:`url(${circle})`, width:'102px', height:'102px',}} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                    <img
                        src={service3}
                        alt="service3"
                        width="65%"
                        height="65%"
                    />
                    </Box>
                    <Typography paddingTop={6} sx={{color:'#ffffff', textAlign:'center', fontFamily:'Inria Sans', fontSize:'20px', fontWeight:400, textTransform:'uppercase',}}>
                        {t('OurServices.service3')}
                    </Typography>
                </Grid>

                {/* Service 4 */}
                <Grid item xs={4} container direction="column" 
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Box sx={{backgroundImage:`url(${circle})`, width:'102px', height:'102px',}} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                    <img
                        src={service4}
                        alt="service4"
                        width="65%"
                        height="65%"
                    />
                    </Box>
                    <Typography paddingTop={6} sx={{color:'#ffffff', textAlign:'center', fontFamily:'Inria Sans', fontSize:'20px', fontWeight:400, textTransform:'uppercase',}}>
                        {t('OurServices.service4')}
                    </Typography>
                </Grid>

                {/* Service 5 */}
                <Grid item xs={4} container direction="column" 
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Box sx={{backgroundImage:`url(${circle})`, width:'102px', height:'102px',}} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                    <img
                        src={service5}
                        alt="service5"
                        width="65%"
                        height="65%"
                    />
                    </Box>
                    <Typography paddingTop={6} sx={{color:'#ffffff', textAlign:'center', fontFamily:'Inria Sans', fontSize:'20px', fontWeight:400, textTransform:'uppercase',}}>
                        {t('OurServices.service5')}
                    </Typography>
                </Grid>

                {/* Service 6 */}
                <Grid item xs={4} container direction="column" 
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Box sx={{backgroundImage:`url(${circle})`, width:'102px', height:'102px',}} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                    <img
                        src={service6}
                        alt="service6"
                        width="65%"
                        height="65%"
                    />
                    </Box>
                    <Typography paddingTop={6} sx={{color:'#ffffff', textAlign:'center', fontFamily:'Inria Sans', fontSize:'20px', fontWeight:400, textTransform:'uppercase',}}>
                        {t('OurServices.service6')}
                    </Typography>
                </Grid>

                {/* Service 7 */}
                <Grid item xs={4} container direction="column" 
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Box sx={{backgroundImage:`url(${circle})`, width:'102px', height:'102px',}} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                    <img
                        src={service7}
                        alt="service7"
                        width="65%"
                        height="65%"
                    />
                    </Box>
                    <Typography paddingTop={6} sx={{color:'#ffffff', textAlign:'center', fontFamily:'Inria Sans', fontSize:'20px', fontWeight:400, textTransform:'uppercase',}}>
                        {t('OurServices.service7')}
                    </Typography>
                </Grid>

                {/* Service 8 */}
                <Grid item xs={4} container direction="column" 
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Box sx={{backgroundImage:`url(${circle})`, width:'102px', height:'102px',}} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                    <img
                        src={service8}
                        alt="service8"
                        width="65%"
                        height="65%"
                    />
                    </Box>
                    <Typography paddingTop={6} sx={{color:'#ffffff', textAlign:'center', fontFamily:'Inria Sans', fontSize:'20px', fontWeight:400, textTransform:'uppercase',}}>
                        {t('OurServices.service8')}
                    </Typography>
                </Grid>

                {/* Service 9 */}
                <Grid item xs={4} container direction="column" 
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Box sx={{backgroundImage:`url(${circle})`, width:'102px', height:'102px',}} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                    <img
                        src={service9}
                        alt="service9"
                        width="65%"
                        height="65%"
                    />
                    </Box>
                    <Typography paddingTop={6} sx={{color:'#ffffff', textAlign:'center', fontFamily:'Inria Sans', fontSize:'20px', fontWeight:400, textTransform:'uppercase',}}>
                        {t('OurServices.service9')}
                    </Typography>
                </Grid>

                {/* Service 10 */}
                <Grid item xs={4} container direction="column" 
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Box sx={{backgroundImage:`url(${circle})`, width:'102px', height:'102px',}} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                    <img
                        src={service10}
                        alt="service10"
                        width="65%"
                        height="65%"
                    />
                    </Box>
                    <Typography paddingTop={6} sx={{color:'#ffffff', textAlign:'center', fontFamily:'Inria Sans', fontSize:'20px', fontWeight:400, textTransform:'uppercase',}}>
                        {t('OurServices.service10')}
                    </Typography>
                </Grid>

                {/* Service 11 */}
                <Grid item xs={4} container direction="column" 
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Box sx={{backgroundImage:`url(${circle})`, width:'102px', height:'102px',}} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                    <img
                        src={service11}
                        alt="service11"
                        width="65%"
                        height="65%"
                    />
                    </Box>
                    <Typography paddingTop={6} sx={{color:'#ffffff', textAlign:'center', fontFamily:'Inria Sans', fontSize:'20px', fontWeight:400, textTransform:'uppercase',}}>
                        {t('OurServices.service11')}
                    </Typography>
                </Grid>

                {/* Service 12 */}
                <Grid item xs={4} container direction="column" 
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Box sx={{backgroundImage:`url(${circle})`, width:'102px', height:'102px',}} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                    <img
                        src={service12}
                        alt="service12"
                        width="65%"
                        height="65%"
                    />
                    </Box>
                    <Typography paddingTop={6} sx={{color:'#ffffff', textAlign:'center', fontFamily:'Inria Sans', fontSize:'20px', fontWeight:400, textTransform:'uppercase',}}>
                        {t('OurServices.service12')}
                    </Typography>
                </Grid>


            </Grid>
        </Box>
        
    )
}