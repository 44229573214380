import Typography from '@mui/material/Typography';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import Link from '@mui/material/Link'

import moveForward from '../../sources/introduction/moveForward.png'
import Intro_image from '../../sources/introduction/Intro_image.png'
import Intro_text_BG from '../../sources/introduction/Intro_text_BG.png'



export default function Introduction() {
    const { t } = useTranslation();

    return (
        <Grid container direction=""  sx={{
                justifyContent: "center",
                alignItems: "center"
            }}>

            <Grid item xs={12} >
                <img
                src={moveForward}
                alt="slogan"
                width="100%"
                height="100%"
                />
            </Grid>

            <Grid item xs={12} py={"80px"} display={'flex'} justifyContent={'center'}>
                <Box py={"86px"}
                    width={'100%'}
                    sx={{
                      borderRadius: "100px 0px 100px 0px",
                      flexDirection: 'column',
                      backdropFilter: 'blur(5px)', // This will make the box blurred
                      background: 'linear-gradient(90deg, rgba(255,255,255,0.7) 50%, rgba(255,255,255,0.4) 100%)',
                      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                    }}
                    display={'flex'}
                    justifyContent={'center'}>
                    <Box display={'flex'} justifyContent={'center'}>
                      <img src={Intro_image} alt="description" width={100} height={100} />
                    </Box>
                    <Box px={5} mb={'24px'} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} >
                      <Typography fontWeight={700} sx={{ fontFamily: 'Charm', fontSize: '100px' }}>K</Typography>
                      <Typography fontWeight={700} sx={{ fontFamily: 'Poppins', fontSize: '36px' }}>ADIMA, {t('Introduction.title1')}<em>{t('Introduction.title2')}</em>{t('Introduction.title3')}</Typography>
                    </Box>
                    <Typography px={5} sx={{ fontFamily: 'InriaSans-Regular', fontSize: '28px', textAlign:"center" }}>
                        {t('Introduction.text1')}
                    </Typography>
                    <Typography px={5} sx={{ fontFamily: 'InriaSans-Regular', fontSize: '28px', textAlign:"center" }}>
                        {t('Introduction.text2')}
                    </Typography>
                </Box>
            </Grid>



            
            
            
            

            
        </Grid>
    );
}