import Typography from '@mui/material/Typography';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import Link from '@mui/material/Link'

import product1 from '../../sources/our_products/product1_Enspiren.png'
import product2 from '../../sources/our_products/product2_Levav.png'
import product3 from '../../sources/our_products/product3.png'
import product4 from '../../sources/our_products/product4.png'
import product5 from '../../sources/our_products/product5.png'
import product6 from '../../sources/our_products/product6.png'


export default function OurProducts() {
    const { t } = useTranslation();

    return (
        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
            <Typography paddingBottom={5} sx={{color:'#5CAEDD', textAlign:'center', fontFamily:'Inria Sans', fontSize:'32px', fontWeight:700}}>{t('OurProducts.title')}</Typography>
            <Grid container direction="row" rowSpacing={3} columnSpacing={{ xs: 3 }}
            sx={{
                justifyContent: "center",
                alignItems: "center",
            }}>
                {/* Product 1 */}
                <Grid  item xs={4} container direction="column" 
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Link href="https://enspiren.hk/" target="_blank">
                        <img
                            src={product1}
                            alt="product1"
                            width="100%"
                            height="100%"
                        />
                    </Link>
                </Grid>

                {/* Product 2 */}
                <Grid  item xs={4} container direction="column" 
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Link href="https://levav.com.hk/" target="_blank">
                        <img
                            src={product2}
                            alt="product2"
                            width="100%"
                            height="100%"
                        />
                    </Link>
                </Grid>

                {/* Product 3 */}
                <Grid  item xs={4} container direction="column" 
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Link>
                        <img
                            src={product3}
                            alt="product3"
                            width="100%"
                            height="100%"
                        />
                    </Link>
                </Grid>

                {/* Product 4 */}
                <Grid  item xs={4} container direction="column" 
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Link>
                        <img
                            src={product4}
                            alt="product4"
                            width="100%"
                            height="100%"
                        />
                    </Link>
                </Grid>

                {/* Product 5 */}
                <Grid  item xs={4} container direction="column" 
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Link>
                        <img
                            src={product5}
                            alt="product5"
                            width="100%"
                            height="100%"
                        />
                    </Link>
                </Grid>

                {/* Product 6 */}
                <Grid  item xs={4} container direction="column" 
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Link>
                        <img
                            src={product6}
                            alt="product6"
                            width="100%"
                            height="100%"
                        />
                    </Link>
                </Grid>


            </Grid>
            <Box marginTop={3} sx={{borderRadius: '350px', background: '#60BAEC', width: '127px', height: '40px'}} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                <Link underline="none">
                    <Typography sx={{color:'#ffffff', textAlign:'center', fontFamily:'Inria Sans', fontSize:'16px', fontWeight:400}}>
                      {t('OurProducts.learn_more')}
                    </Typography>
                </Link>
                
            </Box>
        </Box>
        
    )
}