import Typography from '@mui/material/Typography';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import Link from '@mui/material/Link'

import cityu from '../../sources/supporting_parties/cityu.png'
import polyu from '../../sources/supporting_parties/polyu.png'
import itc from '../../sources/supporting_parties/itc.png'
import hkstp from '../../sources/supporting_parties/hkstp.png'

import takWing from '../../sources/partners/takWing.png'
import firstSeries from '../../sources/partners/firstSeries.png'
import o2Wellness from '../../sources/partners/02Wellness.png'
import art from '../../sources/partners/art.png'
import comrade from '../../sources/partners/comrade.png'
import greenTake from '../../sources/partners/greenTake.png'
import greenLab from '../../sources/partners/greenLab.png'
import mobioz from '../../sources/partners/mobioz.png'
import ninja from '../../sources/partners/ninja.png'

export default function Partners() {
    const { t } = useTranslation();
    
    return (
        <Box>
            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                <Typography paddingBottom={5} sx={{color:'#5CAEDD', textAlign:'center', fontFamily:'Inria Sans', fontSize:'32px', fontWeight:700}}>{t('SupportingParties.title')}</Typography>

                <Grid container direction="row" rowSpacing={3} columnSpacing={{ xs: 3 }}
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}>

                    {/* Supporting parties 1 */}
                    <Grid  item xs={3} container direction="column" 
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <Link href="https://www.cityu.edu.hk/" target="_blank">
                            <img
                                src={cityu}
                                alt="City University of Hong Kong (CityU)"
                                width="100%"
                                height="100%"
                            />
                        </Link>
                    </Grid>

                    {/* Supporting parties 2 */}
                    <Grid  item xs={3} container direction="column" 
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <Link href="https://www.polyu.edu.hk/" target="_blank">
                            <img
                                src={polyu}
                                alt="The Hong Kong Polytechnic University (PolyU)"
                                width="100%"
                                height="100%"
                            />
                        </Link>
                    </Grid>

                    {/* Supporting parties 3 */}
                    <Grid  item xs={3} container direction="column" 
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <Link href="https://www.itc.gov.hk/en/index.html" target="_blank">
                            <img
                                src={itc}
                                alt="The Innovation and Technology Commission (ITC)"
                                width="100%"
                                height="100%"
                            />
                        </Link>
                    </Grid>

                    {/* Supporting parties 4 */}
                    <Grid  item xs={3} container direction="column" 
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <Link href="https://www.hkstp.org/" target="_blank">
                            <img
                                src={hkstp}
                                alt="The Hong Kong Science and Technology Parks Corporation (HKSTP)"
                                width="100%"
                                height="100%"
                            />
                        </Link>
                    </Grid>

                </Grid>
            </Box>

            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                <Typography paddingBottom={5} sx={{color:'#5CAEDD', textAlign:'center', fontFamily:'Inria Sans', fontSize:'32px', fontWeight:700}}>{t('Partners.title')}</Typography>

                <Grid container direction="row" rowSpacing={3} columnSpacing={{ xs: 3 }}
                sx={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                }}>

                    {/* Partners 1 */}
                    <Grid  item xs={3} container direction="column" 
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <Link>
                            <img
                                src={takWing}
                                alt="Tak Wing"
                                width="100%"
                                height="100%"
                            />
                        </Link>
                    </Grid>

                    {/* Partners 2 */}
                    <Grid  item xs={3} container direction="column" 
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <Link>
                            <img
                                src={firstSeries}
                                alt="First Series"
                                width="100%"
                                height="100%"
                            />
                        </Link>
                    </Grid>

                    {/* Partners 3 */}
                    <Grid  item xs={3} container direction="column" 
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <Link>
                            <img
                                src={o2Wellness}
                                alt="02Wellness"
                                width="100%"
                                height="100%"
                            />
                        </Link>
                    </Grid>

                    {/* Partners 4 */}
                    <Grid  item xs={3} container direction="column" 
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <Link>
                            <img
                                src={art}
                                alt="Art"
                                width="100%"
                                height="100%"
                            />
                        </Link>
                    </Grid>

                    {/* Partners 5 */}
                    <Grid  item xs={3} container direction="column" 
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <Link>
                            <img
                                src={comrade}
                                alt="Comrade"
                                width="100%"
                                height="100%"
                            />
                        </Link>
                    </Grid>

                    {/* Partners 6 */}
                    <Grid  item xs={3} container direction="column" 
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <Link>
                            <img
                                src={greenTake}
                                alt="Green Take"
                                width="100%"
                                height="100%"
                            />
                        </Link>
                    </Grid>

                    {/* Partners 7 */}
                    <Grid  item xs={3} container direction="column" 
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <Link>
                            <img
                                src={greenLab}
                                alt="Green Lab"
                                width="100%"
                                height="100%"
                            />
                        </Link>
                    </Grid>

                    {/* Partners 8 */}
                    <Grid  item xs={3} container direction="column" 
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <Link>
                            <img
                                src={mobioz}
                                alt="Mobioz"
                                width="100%"
                                height="100%"
                            />
                        </Link>
                    </Grid>

                    {/* Partners 9 */}
                    <Grid  item xs={3} container direction="column" 
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <Link>
                            <img
                                src={ninja}
                                alt="Ninja"
                                width="100%"
                                height="100%"
                            />
                        </Link>
                    </Grid>

                    

                </Grid>
            </Box>
        </Box>
    )
}